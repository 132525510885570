import styled from 'styled-components'
import { Col } from 'react-styled-flexboxgrid'
import variables from '../variables'

export const Container = styled.div`
  width: 100%;
  margin: 50px 0;
`
export const Content = styled.div`
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  color: ${variables.colors.silverTree.dark}
`

export const StepCol = styled(Col)`
  @media ${variables.media.sm} {
    padding-left: 32px;
  }
  
  pre {
    background: #F5F5F5;
    padding: 1.5rem 1rem;
    border-radius: 4px;
    margin-top: 2rem;
    overflow-x: scroll;
  }
  
  code {
    color: ${variables.colors.silverTree.dark}
  }
  
  ul {
    list-style: none;
  }

  ul li::before {
    content: "\\2022";
    color: ${variables.colors.silverTree.dark};
    font-weight: bold;
    font-size: 1.3rem;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`

export const StepText = styled.div`
  color: #404040;
  p {
    line-height: 1.6;
  }
`
