import React from 'react'
import _ from 'lodash'
import queryString from 'query-string'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { PageLayout } from '../layouts'
import { TextWrapper } from '../styles/base'
import * as styles from '../styles/pages/welcome'
import variables from '../styles/variables'
import {
  PageHeader,
  Steps,
  StepsSection,
  Markdown,
  Section
} from '../components'
import compileTemplate from '../utils/compileTemplate'
import config from '../config'


const propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default function WelcomePage(props) {
  const { data, location } = props
  const { allYaml } = data
  const { edges } = allYaml
  const { node } = _.first(edges)

  const searchParam = queryString.parse(location.search)
  const envId = searchParam.env || searchParam.aitoEnv || 'aito-grocery-store'
  const envBaseUrl = `${envId}.${config.AITO_APP_HOST_NAME}`

  const firstChild = (
    <PageHeader
      header={node.header}
      info={node.headerInfo}
    />
  )
  return (
    <PageLayout
      firstChild={firstChild}
      navbarProps={{ background: variables.colors.oracle.dark }}
      description={node.metaDescription}
      sharingTitle={node.title}
    >
      <Helmet>
        <title>{node.title}</title>
      </Helmet>
      <Section>
        <styles.Container>
          <TextWrapper>
            <styles.Content lg={7} sm={6} xs={12}>
              <Steps>
                {_.map(node.steps, (step, i) => {
                  const template = compileTemplate(step.stepText)
                  return (
                    <StepsSection key={i} header={step.stepHeader}>
                      <styles.StepCol>
                        <styles.StepText>
                          <Markdown>
                            {template({ envBaseUrl })}
                          </Markdown>
                        </styles.StepText>
                      </styles.StepCol>
                    </StepsSection>
                  )
                })}
              </Steps>
            </styles.Content>
          </TextWrapper>
        </styles.Container>
      </Section>
    </PageLayout>
  )
}

WelcomePage.propTypes = propTypes

export const contentQuery = graphql`
query GetWelcomeContent {
  allYaml(filter: {file: {eq: "welcome"}}) {
    edges {
      node {
        title
        header
        headerInfo
        metaDescription
        steps {
          stepHeader
          stepText
        }
      }
    }
  }
}
`
